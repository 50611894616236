import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import Seo from '@core/Seo';
import mq from '@mq';
import RichText from '@core/RichText';

const ContactPage = ({
  data: {
    contentfulContactPage: { text, title },
  },
}) => (
  <>
    <Seo pageTitle="Contact" />
    <main>
      <section>
        <h1>{title}</h1>
        <Text text={text} />
      </section>
    </main>
  </>
);

const Text = styled(RichText)`
  grid-column: 1 / -1;
  font: var(--font-xs);

  ${mq.small} {
    font: var(--font-s);
  }

  ${mq.medium} {
    grid-column: 2 /-2;
  }

  ${mq.large} {
    grid-column: 3 /-3;
  }

  ${mq.huge} {
    grid-column: 6 /-6;
  }
`;

export const ContactPageQuery = graphql`
  query ContactPageQuery($locale: String) {
    contentfulContactPage(node_locale: { eq: $locale }) {
      node_locale
      title
      text {
        raw
      }
    }
  }
`;

export default injectIntl(ContactPage);
